<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="top">
            <img class="left" :src="info.picUrl" alt="" />
            <div class="right">
                <div>
                    <div class="title">{{ info.name }}</div>
                    <div class="tips">
                        <div class="tip">{{ info.typeName }}</div>
                        <div class="tip" v-if="info.isFree == 1">免费</div>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-24.png" alt="" />
                        <span>预约人数：{{ info.applyCount }}人</span>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-25.png" alt="" />
                        <span>{{ info.libraryAddress }}</span>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-26.png" alt="" />
                        <span>{{ info.phoneNumber }}</span>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-27.png" alt="" />
                        <span>{{ info.address }}</span>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-28.png" alt="" />
                        <span>{{ info.startTime ? info.startTime.substr(0,16) : '' }} - {{ info.endTime ? info.endTime.substr(0,16) : '' }}</span>
                    </div>
                </div>
                <div class="butts">
                    <!-- <div class="butt">待审核</div> -->
					<div class="butt" @click="userCourseTraining">预约{{ info.userCourseTrainingId ? '成功' : '' }}</div>
                    <!--                    <div class="butt">待审核</div>-->
                    <!--                    <div class="butt">待审核</div>-->
                    <div @click="collection" class="butt1">
                        <img :src="!info.collectionId ? require('@/assets/images/Frame-39.png') : require('@/assets/images/Vector.png')" alt="" />
                        <span>{{ info.collectionId ? '已' : '' }}收藏</span>
                    </div>
                    <div @click="praise" class="butt1">
                        <img :src="!info.praiseId ? require('@/assets/images/Frame-40.png') : require('@/assets/images/Frame-50.png')" alt="" />
                        <span>{{ info.praiseId ? '已' : '' }}点赞</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="titles">温馨提示</div>
        <div class="details">{{ info.remark }}</div>
        <div class="titles">活动详情</div>
        <div style='white-space: pre-wrap;' class="details" v-html="info.details ? info.details.replace(/<img/g, '<img style=width:100%;height:auto; ') : ''"></div>
    </div>
</template>
<script>
import { getTrainingDetails,userCourseTraining,delUserCourseTraining } from '@/api';
export default {
    data() {
        return {
            info: {},
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '个人中心',
                    to: { path: '/my', query: { tabs: '0' } },
                },
                {
                    title: '活动记录',
                    to: { path: '/my', query: { tabs: '5' } },
                },
                {
                    title: '活动预约记录',
                },
            ],
            statusArr: ['即将开始', '进行中', '已结束'], //  0未开始，1进行中，2已结束
            options: [
                {
                    name: '全部',
                    value: '',
                },
                {
                    name: '演出',
                    value: 0,
                },
                {
                    name: '讲座',
                    value: 1,
                },
                {
                    name: '展览',
                    value: 2,
                },
                {
                    name: '会议',
                    value: 3,
                },
            ]
        };
    },
    methods: {
        getTrainingDetails() {
            getTrainingDetails(this.$route.query.id).then((res) => {
                // console.log(res);
                this.info = res.data;
                this.info.typeName = this.options[this.info.type].name;
                // if (res.code === 200) {
                //     this.data = res.data;
                // }
            });
        },
        collection() {
            if (this.info.collectionId == null) {
                this.$hao.addPraiseCollection({ relatedId: this.info.id, type: 9 }).then((res) => {
                    this.$message.success('收藏成功');
                    console.log(res);
                    this.info.collectionId = res.data.id;
                });
            } else {
                this.$hao.delPraiseCollection(this.info.collectionId).then(() => {
                    this.$message.success('取消收藏成功');
                    this.info.collectionId = null;
                });
            }
            this.$forceUpdate();
        },
        // 点赞
        praise() {
            if (this.info.praiseId === null) {
                this.$hao.addPraiseCollection({ relatedId: this.info.id, type: 10 }).then((res) => {
                    this.$message.success('点赞成功');
                    this.info.praiseId = res.data.id;
                });
            } else {
                this.$hao.delPraiseCollection(this.info.praiseId).then(() => {
                    this.$message.success('取消点赞成功');
                    this.info.praiseId = null;
                });
            }
        },
		userCourseTraining() {
			if (this.info.status !== 0) {
				this.$message.warning('预约已结束');
				return;
			}
			if (this.info.userCourseTrainingId === null) {
				userCourseTraining({ courseTrainingId: this.info.id }).then((res) => {
					this.$message.success('预约成功');
					this.info.userCourseTrainingId = res.id;
				});
			} else {
				delUserCourseTraining(this.info.userCourseTrainingId).then((res) => {
					this.$message.success('取消预约成功');
					this.info.userCourseTrainingId = null;
				});
			}
		},
    },
    created() {
        this.getTrainingDetails();
    },
};
</script>
<style scoped lang="less">
.top {
    display: flex;
    .left {
        width: 580px;
        height: 385px;
        border-radius: 10px;
    }
    .right {
        flex: 1;
        margin-left: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
            font-weight: 700;
            font-size: 20px;
            color: #333333;
            margin-bottom: 8px;
        }
        .tips {
            display: flex;
            margin: 24px 0 36px 0;
            .tip {
                background: #f5f5f5;
                padding: 2px 12px;
                margin-right: 8px;
                font-size: 13px;
                line-height: 19px;
                color: #f33f3e;
                flex: none;
                flex-grow: 0;
                background: rgba(243, 63, 62, 0.1);
                border-radius: 2px;
                order: 1;
            }
        }
        .item {
            display: flex;
            align-items: center;
            margin-top: 16px;
            img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
            span {
                font-size: 16px;
                color: #333333;
                flex: none;
                order: 1;
                flex-grow: 0;
            }
        }
        .butts {
            display: flex;
            align-items: flex-end;
            .butt {
                padding: 12px 110px;
                background: #eda200;
                box-shadow: 0 2px 4px rgba(14, 79, 71, 0.2);
                border-radius: 4px;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #ffffff;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin-top: 37px;
                width: max-content;
            }
            .butt1 {
                display: flex;
                flex-direction: column;
                margin-left: 24px;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                    margin-bottom: 2px;
                }
                span {
                    font-size: 12px;
                    line-height: 17px;
                    color: #666666;
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }
        }
    }
}
.titles {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #333333;
    margin: 24px 0 12px 0;
}
.details {
    font-size: 16px;
    line-height: 23px;
    color: #666666;
}
.details:last-child {
    margin-bottom: 150px;
}
</style>
